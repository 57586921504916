<template>
  <v-bottom-sheet v-model="open" persistent>
    <v-sheet
      min-height="200"
      class="d-flex align-center flex-column justify-center"
    >
      <v-row dense align="center" justify="center" class="text-center">
        <v-col cols="12" md="5" sm="12" class="mb-1" v-if="mdAndUp">
          <v-img height="150" contain :src="push_notification_image"></v-img>
        </v-col>
        <v-col cols="12" md="5" sm="12" class="mb-1 d-flex flex-column">
          <strong class="fw-700 fs-20 primary--text">
            Want to get notification from us?
          </strong>
          <div class="">
            <v-btn
              width="100"
              class="mt-3 mx-2"
              color="primary"
              @click="handleYes"
            >
              Yes
            </v-btn>
            <v-btn
              width="100"
              class="mt-3 mx-2"
              color="secondary"
              @click="handleLater"
            >
              Later
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row
        dense
        align="center"
        justify="center"
        class="fs-11 fw-700 secondary--text"
      >
        Notifications are used on timers, messages, tasks , projects and many
        more.
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'PushNotificationPopUp',
  created() {
    this.$event.$on('ask-notification-permission', () => {
      if (!('Notification' in window)) {
        this.appSnackbar(
          `This browser does not support push notification. Push notifications from ${settings.SiteName} will not proceed`,
          'info'
        )
      } else if (Notification.permission !== 'granted') {
        this.open = true
      }
    })
  },
  mounted() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      this.appSnackbar(
        `This browser does not support push notification. Push notifications from ${settings.SiteName} will not proceed`,
        'info'
      )
    } else if (Notification.permission === 'default') {
      this.open = true
    }
  },
  data: () => ({
    open: false,
    push_notification_image: require('@/assets/tutorials/push-notification.png')
  }),
  methods: {
    handleYes() {
      this.open = false
      const vm = this
      Notification.requestPermission().then(function (status) {
        if (status === 'denied') {
          localStorage.setItem('push-notification', 'denied')
          vm.appSnackbar(
            'We respect your decision. You can enable it anytime you want.',
            'info'
          )
        } else if (status === 'granted') {
          localStorage.setItem('push-notification', 'granted')
          vm.appSnackbar(
            'Great! Push notification is now available.',
            'success'
          )
        }
      })
    },
    handleLater() {
      this.open = false
      localStorage.setItem('push-notification', 'default')
      this.appSnackbar('Ok, You can enable it anytime you want.', 'info')
    }
  }
}
</script>

<style></style>
