import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'

export const echo_mixins = {
    computed: {
        ...mapGetters(['user']),
        companyId() {
            return this.user.company.id
        }
    },
    mounted() {
        if (this.settings.realtime_enabled) {
            this.joinCompanyPresence()
            this.joinToAppEvents()
            this.joinToUserPresence()
        }
    },
    methods: {
        ...mapMutations('chatNotifications', ['add_chat']),
        notify(title, body) {
            this.$notification.show(
                title, {
                    icon: this.settings.squareLogo,
                    body: body
                }, {}
            )
        },
        handleTimerConfirmation(payload) {
            if (
                payload.type === 'timer_need_confirmation' &&
                Object.values(payload.targets).includes(this.user.id)
            ) {
                this.$event.$emit('timer-need-confirmation', payload)
            } else if (
                payload.type === 'timer_force_off' &&
                Object.values(payload.targets).includes(this.user.id)
            ) {
                this.$event.$emit('timer-force-off', payload)
                this.$event.$emit('global-timer-stopped')
            }
        },
        handleNotificationPayload(payload, type) {
            if (payload.data && payload.data.title) {
                if (Object.values(payload.data.targets).includes(this.user.id)) {
                    if (payload.data.notif_only === false)
                        this.$store.commit(`chatNotifications/add_${type}`, payload)
                    this.handleTimerConfirmation(payload.data)
                    this.notify(payload.data.title, payload.data.message)
                } else if (
                    payload.data.title &&
                    Object.values(payload.data.targets).length === 0
                ) {
                    if (payload.data.notif_only === false)
                        this.$store.commit(`chatNotifications/add_${type}`, payload)
                    this.handleTimerConfirmation(payload.data)
                    this.notify(payload.data.title, payload.data.message)
                }
            } else {
                if (
                    Object.values(payload.targets).length > 0 &&
                    Object.values(payload.targets).includes(this.user.id)
                ) {
                    if (payload.notif_only === false)
                        this.$store.commit(`chatNotifications/add_${type}`, payload)
                    this.handleTimerConfirmation(payload)
                    this.notify(payload.title, payload.message)
                } else if (
                    payload.title &&
                    Object.values(payload.targets).length === 0
                ) {
                    if (payload.notif_only === false)
                        this.$store.commit(`chatNotifications/add_${type}`, payload)
                    this.handleTimerConfirmation(payload)
                    this.notify(payload.title, payload.message)
                }
            }
        },
        handleChatPayload(payload) {
            let index = payload.receivers.findIndex(
                (user) => user.id === this.user.id
            )
            if (~index) {
                this.add_chat(payload.message)
            }
        },
        joinCompanyPresence() {
            const vm = this
            this.$echo
                .join(`as-company.${this.user.company.id}`)
                .here((users) => {
                    vm.$store.commit('onlineUsers/set_all_users', users)
                })
                .joining((user) => {
                    vm.$store.commit('onlineUsers/set_user_logged_in', user)
                })
                .leaving((user) => {
                    vm.$store.commit('onlineUsers/set_user_logged_out', user)
                })
                .listen('App\\Events\\ProjectTaskNotification', ({ payload }) => {
                    if (payload.receivers.includes(vm.user.id)) {
                        vm.notify(payload.title, payload.message)
                    }
                })
                .listen('App\\Events\\CompanyEvent', (payload) => {
                    if (payload.type === 'configs') {
                        vm.$store.commit('set_user_company', payload)
                    }
                })
                .listen('.NewCommentEvent', ({ payload }) => {
                    vm.$event.$emit('new-comment', payload)
                })
                .listen('.DeleteCommentEvent', ({ payload }) => {
                    vm.$event.$emit('delete-comment', payload)
                })
                .listen(
                    'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
                    (payload) => {
                        if (payload.type.includes('CompanyNotification')) {
                            vm.handleNotificationPayload(payload, 'notification')
                        }
                    }
                )
                .listen('App\\Events\\ChatNotification', (payload) => {
                    vm.handleChatPayload(payload)
                })
        },
        joinToAppEvents() {
            const vm = this
            this.$echo.channel(`as-apps`).listen('.AppEvents', (payload) => {
                if (payload.command === 'refetchConfigurations') {
                    vm.$store.dispatch('fetchConfigurations')
                }
            })
        },
        joinToUserPresence() {
            const vm = this
            this.$echo
                .join(`as-user.${this.user.id}`)
                .joining((user) => {
                    console.log('Connected to as-user channel.')
                })
                .leaving((user) => {
                    console.log('Disconnected to as-user channel')
                })
                .listen('.ChatMessageSent', ({ message }) => {
                    vm.$event.$emit('chat-message-received', message)
                    vm.$store.commit('conversations/insertMessageToConversation', {
                        id: message.conversation_id,
                        message: message
                    })
                    vm.$store.commit(
                        'conversations/increment_conversation_unread_count',
                        message.conversation_id
                    )
                    if (message.sender.id !== vm.user.id) {
                        vm.notify(
                            `New message from ${message.sender.first_name}`,
                            message.formatted_body
                        )
                    }
                })
                .listen('.ChatMessageDeleted', ({ message }) => {
                    vm.$event.$emit('chat-message-deleted', message)
                    vm.$store.commit('conversations/deleteMessageFromConversation', {
                        id: message.conversation_id,
                        message: message
                    })
                })
                .listen('.AlarmEvent', ({ alarm }) => {
                    vm.$event.$emit('alarmed', alarm)
                })
        }
    }
}