<template>
  <div id="floating-me" class="d-flex align-end justify-end">
    <div
      class="d-flex flex-nowrap"
      v-if="canBeDisplayInRoutes && active_conversations.length"
    >
      <VueChat
        v-for="(conversation, index) in active_conversations"
        :key="index"
        :is-group="conversation.type !== 'private'"
        :conversation="conversation"
        class="d-chat d-flex align-end justify-end"
        @closed-chat="removeFromActiveConversations(conversation)"
        @edit-conversation="setEditConversation"
      ></VueChat>
    </div>
    <v-menu
      v-if="canBeDisplayInRoutes"
      offset-y
      left
      v-click-outside="handleClickOutside"
      v-model="openList"
      top
      :close-on-click="true"
      :close-on-content-click="false"
      origin="right"
      nudge-top="5"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          :content="messageCount"
          :value="false"
          color="green"
          overlap
          style="z-index: 9999"
          left
        >
          <v-btn
            class="text-none"
            color="primary"
            min-width="100"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              {{ !openList ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
            CHAT
            <span class="ml-3 fs-11">
              Online: {{ all_users.length >= 2 ? all_users.length - 1 : 0 }}
            </span>
          </v-btn>
        </v-badge>
      </template>
      <v-card outlined flat width="300" class="overflow-hidden">
        <v-card-title class="py-2 d-flex align-center justify-space-between">
          <HelpToolTip
            text="Close"
            icon-color="primary"
            @click:icon="openList = false"
            icon-class="mr-1"
            icon="mdi-close-circle-outline"
          ></HelpToolTip>
          <strong class="primary--text fs-20 fw-900">Chat </strong>
          <div class="d-flex align-center justify-end">
            <HelpToolTip
              text="Create new conversation group"
              icon-color="primary"
              @click:icon="open_create_group_dialog = true"
              icon-class="mr-1"
              icon="mdi-message-plus"
            ></HelpToolTip>
            <HelpToolTip
              v-if="$route.name !== 'chat'"
              text="Expand Chat"
              icon-color="primary"
              @click:icon="navigateToChat"
              icon-class="mr-1"
              icon="mdi-wechat"
            ></HelpToolTip>
            <HelpToolTip
              text="Refresh conversation list"
              icon-color="primary"
              @click:icon="fetchConversations"
              :disabled="fetching"
              :loading="fetching"
              icon="mdi-refresh"
            ></HelpToolTip>
          </div>
        </v-card-title>
        <v-list
          v-if="canBeChatPrivately.length"
          dense
          max-height="80vh"
          class="overflow-y-auto"
        >
          <template v-for="convo in canBeChatPrivately">
            <v-list-item
              v-if="convo.type === 'private' && convo.target"
              :class="{ 'secondary light--text': inActiveChats(convo) }"
              @click="toggleConversation(convo)"
              :key="convo.id"
            >
              <v-badge
                bordered
                bottom
                :color="convo.is_online ? 'success' : 'secondary'"
                dot
                offset-x="20"
                offset-y="10"
              >
                <v-avatar size="40" class="mr-2">
                  <v-img
                    contain
                    :src="
                      convo.target.image_url
                        ? convo.target.image_url
                        : settings.notFound
                    "
                  ></v-img>
                </v-avatar>
              </v-badge>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  :class="{ 'light--text': inActiveChats(convo) }"
                >
                  {{
                    convo.target.fullname ? convo.target.fullname : '' | ucwords
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  class="secondary--text"
                  :class="{ 'light--text': inActiveChats(convo) }"
                >
                  {{ convo.target.job_title | ucwords }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="convo.unread_messages_count">
                <v-icon color="success" small>mdi-email-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="convo.type !== 'private'"
              :class="{ 'secondary light--text': inActiveChats(convo) }"
              @click="toggleConversation(convo)"
              :key="convo.id"
            >
              <div
                class="d-flex align-center justify-start fullwidth flex-nowrap"
              >
                <Avatars
                  :items="convo.participants"
                  class="mr-2"
                  :count="1"
                ></Avatars>
                <strong
                  class="primary--text fs-13"
                  :class="{ 'light--text': inActiveChats(convo) }"
                >
                  {{ convo.name | ucwords }}
                </strong>
              </div>
              <v-list-item-icon v-if="convo.unread_messages_count">
                <v-icon color="success" small> mdi-email-alert </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
        <Empty v-else headline="No active conversations"></Empty>
      </v-card>
    </v-menu>
    <GroupChatDialog
      v-model="open_create_group_dialog"
      :user-list="canBeAddedToChat"
      :current-list="null"
      @save="handleSaveConversation"
    ></GroupChatDialog>
    <GroupChatDialog
      v-model="open_edit_group_dialog"
      :user-list="canBeAddedToChat"
      :conversation="activeConversation"
      @update="handleUpdateConversation"
    ></GroupChatDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueChat from './VueChat.vue'
import _ from 'lodash'
import GroupChatDialog from '@/modules/Chat/components/GroupChatDialog/GroupChatDialog.vue'
export default {
  components: {
    VueChat,
    GroupChatDialog
  },
  created() {
    this.closeAllActiveConversations()
    this.set_max_active_conversation(this.mdAndUp ? 3 : 1)
    if (!this.both_conversations.length) {
      this.fetchConversations()
    }
  },
  computed: {
    ...mapGetters(['users', 'user']),
    ...mapGetters('onlineUsers', ['all_users']),
    ...mapGetters('conversations', [
      'both_conversations',
      'active_conversations',
      'fetching'
    ]),
    canBeDisplayInRoutes() {
      return !['chat', 'project-chats'].includes(this.$route.name)
    },
    canBeAddedToChat() {
      return _.cloneDeep(this.users).filter((i) => {
        if (this.user.id === i.id) return false
        if (this.user.is_admin || this.user.is_manager) return true
        if (this.user.client && i.is_client) return false
        if (
          !this.user.is_owner &&
          !this.user.is_admin &&
          !this.user.is_manager &&
          i.is_client
        )
          return false
        else return true
      })
    },
    canBeChatPrivately() {
      if (!this.both_conversations.length) return []
      return _.cloneDeep(this.both_conversations)
        .filter((i) => {
          if (this.user.is_admin || this.user.is_manager) return true
          if (this.user.client && i.target && i.target.is_client) return false
          if (
            !this.user.is_owner &&
            !this.user.is_admin &&
            !this.user.is_manager &&
            i.target &&
            i.target.is_client
          )
            return false
          else return true
        })
        .map((i) => {
          i.is_online = i.type === 'private' ? this.isOnline(i.target) : false
          return i
        })
        .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
    }
  },
  data: () => ({
    open_create_group_dialog: false,
    open_edit_group_dialog: false,
    messageCount: 1,
    activeChats: [],
    activeConversation: null,
    openList: false,
    open: false
  }),
  methods: {
    ...mapMutations('conversations', [
      'toggleActiveConversations',
      'removeFromActiveConversations',
      'set_max_active_conversation',
      'closeAllActiveConversations'
    ]),
    ...mapActions('conversations', [
      'createConversation',
      'updateConversation',
      'readConversation'
    ]),
    toggleConversation(conversation) {
      this.openList = false
      this.toggleActiveConversations(conversation)
      this.readConversation(conversation.id)
    },
    ...mapActions('conversations', ['fetchConversations']),
    isOnline(user) {
      if (!this.all_users.length || !user) return false
      let id = user.hasOwnProperty('id') ? user.id : user
      let index = this.all_users.findIndex((i) => i.id === id)
      return ~index ? true : false
    },
    inActiveChats(conversation) {
      let id = conversation.hasOwnProperty('id')
        ? conversation.id
        : conversation
      let index = this.active_conversations.findIndex((i) => i.id === id)
      return ~index ? true : false
    },
    handleSaveConversation(payload) {
      this.createConversation({
        payload: payload,
        cb: (conversation) => {
          this.open_create_group_dialog = false
          this.toggleConversation(conversation)
          this.appSnackbar('New group conversation created')
        }
      })
    },
    handleUpdateConversation(payload) {
      this.updateConversation({
        conversation_id: this.activeConversation.id,
        payload: payload,
        cb: (conversation) => {
          this.open_edit_group_dialog = false
          this.activeConversation = false
          this.appSnackbar('Conversation updated')
        }
      })
    },
    navigateToChat() {
      this.openList = false
      this.$router.push({ name: 'chat' }).catch(() => {})
    },
    setEditConversation(conversation) {
      this.activeConversation = conversation
      this.open_edit_group_dialog = true
    },
    handleClickOutside() {
      // if (this.openList) this.openList = false
    }
  }
}
</script>

<style lang="scss">
#floating-me {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  height: 0;
  margin-bottom: 5px;
  margin-right: 5px;

  .sc-chat-window {
    bottom: 0;
    height: 450px;
    z-index: 99;
    right: 5px;
    position: relative;
    margin-left: 5px;
    border: 1px solid #cdcdcd;
    .sc-header {
      min-height: 60px;
    }
    .sc-message {
      width: 330px;
      padding-bottom: 5px;

      .sc-message--text-content {
        margin-bottom: auto;
      }
    }
    .sc-user-input--buttons {
      align-items: center;
    }
    .file-container {
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      max-width: 100%;
      font-size: 10px;
    }
  }
}
</style>
