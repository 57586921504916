var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('beautiful-chat',{attrs:{"participants":_vm.participants,"on-message-was-sent":_vm.onMessageWasSent,"message-list":_vm.conversationMessage,"new-messages-count":_vm.newMessagesCount,"is-open":_vm.isChatOpen,"close":_vm.closeChat,"open":function () { return true; },"show-emoji":true,"show-file":true,"show-edition":true,"show-deletion":true,"show-typing-indicator":_vm.showTypingIndicator,"show-launcher":false,"show-close-button":true,"colors":_vm.colors,"always-scroll-to-bottom":_vm.alwaysScrollToBottom,"disable-user-list-toggle":true,"message-styling":_vm.messageStyling},on:{"scrollToTop":_vm.handleScrollToTop,"onType":_vm.handleOnType,"edit":_vm.editMessage},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center fullwidth justify-space-between"},[(_vm.conversation.type === 'private')?_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":_vm.conversation.is_online ? 'success' : 'secondary',"dot":"","offset-x":"20","offset-y":"10"}},[_c('v-avatar',{staticClass:"mr-2 border-2 border-color--light",attrs:{"size":"40"}},[_c('v-img',{attrs:{"contain":"","src":_vm.conversation.target.image_url}})],1)],1),_c('strong',{staticClass:"fs-15"},[_vm._v(_vm._s(_vm.conversation.target.fullname))])],1):_c('div',{staticClass:"d-flex align-center justify-start"},[_c('Avatars',{staticClass:"mr-2",attrs:{"items":_vm.conversation.participants,"count":1}}),_c('strong',{staticClass:"fs-15"},[_vm._v(_vm._s(_vm.conversation.name))])],1),(_vm.conversation && _vm.conversation.type === 'group')?_c('v-menu',{attrs:{"left":"","bottom":"","z-index":"999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-message-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Settings")])])]}}],null,false,378071958)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.can_actions),function(item){return _c('v-list-item',{key:item.value,staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.handleAction(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1):_vm._e()],1)]},proxy:true},{key:"text-message-body",fn:function(ref){
var message = ref.message;
return [(message.data.text && message.data.text != 'undefined')?_c('div',{domProps:{"innerHTML":_vm._s(message.data.text)}}):_vm._e(),(message.data.file)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Attachment',{attrs:{"max-width":"200","item":message.data.file}},[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{staticClass:"text-none",attrs:{"href":message.data.file.url,"depressed":""}},[_vm._v(" Download ")])],1):_vm._e()],1)]}}],null,true)}):_vm._e()]}},{key:"system-message-body",fn:function(ref){
var message = ref.message;
return [_c('Empty',{attrs:{"headline":message.text}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }