<template>
  <CustomDialog
    @button2="save_group"
    :button2-text="isEdit ? 'Update' : 'Create'"
    @button1="clear_and_close"
    v-model="dialog"
    max-width="800"
    :title="!isEdit ? 'Create Group Conversation' : 'Update Group Conversation'"
    :main-btn-disabled="!valid || all_selected.length.length < 2"
  >
    <template v-slot:content>
      <v-card class="ma-0" flat min-height="300">
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-row no-gutters dense>
              <v-col cols="12" sm="12" md="7">
                <label class="subtitle-2 primary--text">Group Name *</label>
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-dialpad"
                  clearable
                  class="mb-1"
                  :rules="[requiredRule()]"
                  v-model="group_name"
                  required
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  label="Search for people to add"
                  required
                  clearable
                  class="mb-1 mt-2"
                  hide-details="auto"
                  prepend-inner-icon="search"
                  v-model="search"
                  @keydown="filter_users"
                  @click:clear="filter_users"
                ></v-text-field>
                <v-list
                  dense
                  v-if="filtered_by_search.length"
                  id="filtered_by_search_wrapper"
                >
                  <v-list-item
                    v-for="item in filtered_by_search"
                    :key="item.title"
                    @click="selected(item)"
                    :disabled="item.id === user.id"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="item.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content class="primary--text">
                      <v-list-item-title v-text="item.fullname" />
                      <v-list-item-subtitle v-text="item.job_title" />
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon v-show="is_selected(item)" color="success">
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
                <Empty v-else headline="No available user found!"></Empty>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-list subheader dense id="all_selected_wrapper">
                  <v-subheader class="primary--text subtitle-2">
                    Selected ({{ all_selected.length }})
                  </v-subheader>
                  <template v-if="all_selected.length > 0">
                    <v-list-item
                      v-for="item in all_selected"
                      :key="item.title"
                      :disabled="item.id === user.id"
                    >
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="settings.loader"
                          :src="item.image_url"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="primary--text">
                        <v-list-item-title v-text="item.fullname" />
                        <v-list-item-subtitle v-text="item.job_title" />
                      </v-list-item-content>
                      <v-list-item-icon v-if="item.id !== user.id">
                        <v-icon @click="selected(item)" small color="danger">
                          close
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GroupChatDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    userList: Array,
    conversation: { type: Object, default: null }
  },
  data() {
    return {
      valid: false,
      dialog: false,
      all_users: [],
      filtered_by_search: [],
      all_selected: [],
      search: '',
      group_name: '',
      btnloading: false
    }
  },
  computed: {
    ...mapGetters(['user', 'users']),
    isEdit() {
      return this.conversation ? true : false
    },
    payload() {
      return {
        group_name: this.group_name,
        users: this.all_selected.map((value) => {
          return value.id
        })
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.dialog = val
        this.search = ''
        this.btnloading = false
        if (val && this.userList) {
          this.resetValidation()
          this.all_users = _cloneDeep(this.userList)
          this.filtered_by_search = _cloneDeep(this.userList)
          if (!this.isEdit) {
            this.all_selected = [this.user]
            this.group_name = null
          }
        }
      },
      immediate: true
    },
    dialog: {
      handler(val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    conversation: {
      handler(val) {
        if (val) {
          this.all_selected = val.participants.length
            ? _cloneDeep(val.participants)
            : [this.user]
          this.group_name = val.name || null
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    is_selected(user) {
      return this.all_selected.find((i) => i.id === user.id) ? true : false
    },
    save_group() {
      if (this.valid && this.all_selected.length > 1) {
        this.btnloading = true
        this.$emit(this.isEdit ? 'update' : 'save', this.payload)
      }
    },
    clear_and_close() {
      this.dialog = false
    },
    select(item, value) {
      let index = this.filtered_by_search.findIndex(
        (user) => item.id === user.id
      )
      if (~index) {
        this.filtered_by_search[index].is_selected = value
      }
    },
    selected(item) {
      let index = this.all_selected.findIndex((user) => item.id === user.id)
      if (~index) {
        this.select(item, false)
        this.all_selected.splice(index, 1)
      } else {
        this.select(item, true)
        this.all_selected.push(item)
      }
    },
    filter_users(event) {
      setTimeout(() => {
        if (this.search && this.search !== '') {
          this.filtered_by_search = _cloneDeep(this.all_users).filter(
            (item) => {
              return item.fullname
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
            }
          )
        } else {
          this.filtered_by_search = _cloneDeep(this.all_users)
        }
      }, 1)
    }
  }
}
</script>
<style scoped>
#all_selected_wrapper {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

#filtered_by_search_wrapper {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
