<template>
  <beautiful-chat
    :participants="participants"
    :on-message-was-sent="onMessageWasSent"
    :message-list="conversationMessage"
    :new-messages-count="newMessagesCount"
    :is-open="isChatOpen"
    :close="closeChat"
    :open="() => true"
    :show-emoji="true"
    :show-file="true"
    :show-edition="true"
    :show-deletion="true"
    :show-typing-indicator="showTypingIndicator"
    :show-launcher="false"
    :show-close-button="true"
    :colors="colors"
    :always-scroll-to-bottom="alwaysScrollToBottom"
    :disable-user-list-toggle="true"
    :message-styling="messageStyling"
    @scrollToTop="handleScrollToTop"
    @onType="handleOnType"
    @edit="editMessage"
  >
    <template v-slot:header>
      <div class="d-flex align-center fullwidth justify-space-between">
        <div
          class="d-flex align-center justify-start"
          v-if="conversation.type === 'private'"
        >
          <v-badge
            bordered
            bottom
            :color="conversation.is_online ? 'success' : 'secondary'"
            dot
            offset-x="20"
            offset-y="10"
          >
            <v-avatar size="40" class="mr-2 border-2 border-color--light">
              <v-img contain :src="conversation.target.image_url"></v-img>
            </v-avatar>
          </v-badge>
          <strong class="fs-15">{{ conversation.target.fullname }}</strong>
        </div>
        <div class="d-flex align-center justify-start" v-else>
          <Avatars
            :items="conversation.participants"
            :count="1"
            class="mr-2"
          ></Avatars>
          <strong class="fs-15">{{ conversation.name }}</strong>
        </div>
        <v-menu
          v-if="conversation && conversation.type === 'group'"
          left
          bottom
          z-index="999"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="white"
                  icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-message-cog</v-icon>
                </v-btn>
              </template>
              <span>Settings</span>
            </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item
              @click.stop="handleAction(item)"
              v-for="item in can_actions"
              :key="item.value"
              class="primary--text"
            >
              <v-list-item-icon class="mr-2">
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:text-message-body="{ message }">
      <div
        v-if="message.data.text && message.data.text != 'undefined'"
        v-html="message.data.text"
      ></div>
      <v-hover v-if="message.data.file" v-slot:default="{ hover }">
        <Attachment max-width="200" :item="message.data.file">
          <v-overlay absolute v-if="hover">
            <v-btn :href="message.data.file.url" depressed class="text-none">
              Download
            </v-btn>
          </v-overlay>
        </Attachment>
      </v-hover>
    </template>
    <template v-slot:system-message-body="{ message }">
      <Empty :headline="message.text"></Empty>
    </template>
  </beautiful-chat>
</template>
<script>
import Vue from 'vue'
import Chat from 'vue-beautiful-chat'
Vue.use(Chat)
import _cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    //
  },
  props: {
    isGroup: { type: Boolean, default: false },
    conversation: { type: Object, required: true }
  },
  watch: {
    conversation: {
      handler: function (val) {
        this.isChatOpen = val ? true : false
        if (val) {
          this.fetchConversationMessages(val.id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    conversationMessage() {
      if (!this.conversation) return
      let messages = _cloneDeep(
        this.$store.getters['conversations/convoMessages'](this.conversation.id)
      ).map((i) => {
        return {
          type: 'text',
          author: i.sender_id === this.user.id ? 'me' : i.sender_id,
          data: {
            text: i.formatted_body !== 'undefined' ? i.formatted_body : '',
            file: i.attachment || null
          }
        }
      })
      if (messages.length) return messages
      else
        return [
          {
            type: 'system',
            data: { text: 'No conversation yet. Start one ;)' }
          }
        ]
    },
    participants() {
      return this.conversation && this.conversation.participants.length
        ? _cloneDeep(this.conversation.participants).map((i) => {
            return {
              id: i.id,
              name: i.fullname,
              imageUrl: i.image_url
            }
          })
        : []
    },
    amIOwner() {
      if (!this.conversation || this.conversation.type !== 'group') return false
      return this.conversation.props &&
        this.conversation.props.group_creator &&
        this.conversation.props.group_creator == this.user.id
        ? true
        : false
    },
    can_actions() {
      if (!this.conversation || this.conversation.type != 'group') return []
      if (this.amIOwner) {
        return _cloneDeep(this.actions)
      } else return _cloneDeep(this.actions).filter((i) => !i.ownerOnly)
    }
  },
  data() {
    const vm = this
    return {
      messageList: [],
      isChatOpen: false,
      newMessagesCount: 0,
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#223a6b',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#ececec',
          text: '#223a6b'
        },
        receivedMessage: {
          bg: '#ececec',
          text: '#223a6b'
        },
        userInput: {
          bg: '#ececec',
          text: '#223a6b'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      actions: [
        {
          name: 'Leave conversation',
          icon: 'mdi-message-arrow-left',
          value: 'leave_conversation',
          ownerOnly: false
        },
        {
          name: 'Manage conversation',
          icon: 'mdi-message-cog',
          value: 'manage_conversation',
          ownerOnly: true
        }
      ]
    }
  },
  methods: {
    ...mapActions('conversations', [
      'fetchConversationMessages',
      'fetchConversationMoreMessages',
      'leaveConversation',
      'sendMessage'
    ]),
    onMessageWasSent(message) {
      let formData = new FormData()
      if (message.type === 'emoji') {
        formData.append('message', message.data.emoji)
      } else formData.append('message', message.data.text)
      if (message.data.file) {
        formData.append('file', message.data.file)
      }
      this.sendMessage({ id: this.conversation.id, payload: formData })
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
      this.$emit('closed-chat', true)
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
      if (this.conversation) {
        this.fetchConversationMoreMessages(this.conversation.id)
      }
    },
    handleOnType() {
      console.log('Emit typing event')
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id)
      m.isEdited = true
      m.data.text = message.data.text
    },
    handleAction(item) {
      if (item.value === 'leave_conversation') {
        this.appConfirmation(
          'Are you sure you want to leave this conversation?',
          () => {
            this.leaveConversation({
              conversation_id: this.conversation.id,
              cb: () => {
                this.appSnackbar('You have left the conversation')
              }
            })
          }
        )
      } else if (item.value === 'manage_conversation') {
        this.$emit('edit-conversation', this.conversation)
      }
    }
  }
}
</script>
<style lang="scss"></style>
