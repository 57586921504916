<template>
  <v-app id="sandbox">
    <LeftSidebar></LeftSidebar>
    <MainHeader></MainHeader>
    <RightSidebar></RightSidebar>
    <ConnectSidebar></ConnectSidebar>
    <CardSidebar></CardSidebar>
    <MessageSidebar></MessageSidebar>
    <NotificationSidebar></NotificationSidebar>
    <v-main style="background: #f2f1f7">
      <v-container fluid style="background: #f2f1f7">
        <v-row dense>
          <v-col md="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
        <FloatingButton></FloatingButton>
      </v-container>
      <PushNotificationPopUp></PushNotificationPopUp>
    </v-main>
    <v-footer
      :inset="footer.inset"
      class="align-end justify-end"
      app
      v-if="hasFooter"
    >
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import LeftSidebar from './components/LeftSidebar.vue'
import RightSidebar from './components/RightSidebar.vue'
import ConnectSidebar from './components/ConnectSidebar.vue'
import CardSidebar from './components/CardSidebar.vue'
import MainHeader from './components/MainHeader.vue'
import MessageSidebar from './components/MessageSidebar.vue'
import NotificationSidebar from './components/NotificationSidebar.vue'
import { echo_mixins } from './echo_mixins'
import FloatingButton from '@/common/ChatBox/FloatingButton.vue'
import PushNotificationPopUp from './components/PushNotificationPopUp.vue'
export default {
  name: 'Dashboard',
  mixins: [echo_mixins],
  components: {
    LeftSidebar,
    MainHeader,
    RightSidebar,
    ConnectSidebar,
    CardSidebar,
    MessageSidebar,
    NotificationSidebar,
    FloatingButton,
    PushNotificationPopUp
  },
  data: () => ({
    footer: {
      inset: true
    },
    fab: false
  }),
  mounted() {
    this.$store.commit('dashitems/init_forSelection', this.user_roles)
  },
  computed: {
    ...mapGetters(['user_roles', 'user']),
    ...mapGetters('app', ['hasFooter'])
  }
}
</script>

<style></style>
